import './App.css';
import { connectWallet } from './abstract/wallet-connection';
import metamask from './metamask.png';
import walletConnect from './walletconnect.svg';
import coinbase from './coinbase.svg';
import pdf from './tutoriel.pdf';
import { disconnectWallet } from './abstract/wallet-disconnect';
import { getCurrentChainId, requestNetworkChange } from './abstract/network-interaction';
import { useEffect, useState } from 'react';
import { getBlockedNfts, getMintedNfts, mintNft } from './abstract/contract-interaction';
import { getConnectionInfo } from './abstract/utils';
import { render } from 'react-dom';
function App() {

  const [chainId, setChainId] = useState<number>();
  const [connectedAddr, setConnectedAddr] = useState<string>();
  const [error, setError] = useState<string>();
  const [preMintedArray, setPreMintedArray] = useState<number[]>();
  const [mintedArray, setMintedArray] = useState<number[]>();
  const [txSuccess, setTxSuccess] = useState<string>();
  const [hasFunds, setHasFunds] = useState<string>();
  const [idToMint, setIdToMint] = useState<number>();
  const [connectionInfo, setConnectionInfo] = useState<{
    isConnected: boolean | undefined,
    address: string | undefined
  }>();

    const [wallet, setAccount] = useState<any>('null');

  useEffect(() => {
    (async () => {
        const { isConnected, address } = await getConnectionInfo();
        const listMint = await getMintedNfts();
          localStorage.setItem('ArrMintedNfts', JSON.stringify(listMint) )
      if (isConnected) {
        setConnectedAddr(address);
      }
    })()

  }, [])

  const connect = async (type: "Metamask" | "Coinbase" | "WalletConnect") => {
    try {
      const { isConnected, isSigRejected, sigHash, address, isNetworkChangeRejected } = await connectWallet(
        type,
        () => disconnectWallet(() => setConnectedAddr("Disconnected from wallet")),
        () => { setConnectedAddr("") },
        true,
        "Sign to login"
      )
        
    const { isRejected } = await requestNetworkChange(parseInt(process.env.REACT_APP_CHAIN_ID as unknown as string));
              if (isRejected) {
                setError("You must switch network in order to use your wallet")
              }

      if (isNetworkChangeRejected) {
        setError("Please switch network in order to use your wallet on the website");
      } else if (isSigRejected) {
        setError("Please sign to use your wallet on the website");
      } else if(!address){
        setError("Please check if you have a wallet installed or if it is unlocked");
      }

      if (isConnected) {
        setConnectedAddr(address);
        setError("");
      }
    } catch (error) {
      setError("Error on connecting wallet")
    }
  }
    
    const haveAccount = (type: true | false) => {
        setAccount(type)
    };

    const returnLoad = (type: any, type2: any) => {
        setAccount(type);
        if (type === 'null') {
            localStorage.removeItem('idNft')
        }
        setConnectedAddr(type2);

    }

  const getUnavailableTokens = async () => {
    const tokens = await getBlockedNfts();
    setPreMintedArray(tokens);
  }

    const mint = async () => {
        const { isConnected } = await getConnectionInfo();

        let idToMint = Number(localStorage.getItem('idNft'));

        if (!isConnected) {
            setError("Please connect first");
            return;
        }
        setError("Please confirm transaction")
        const { isSuccess, hasFunds: canPay, hasError, isNetworkChangeRejected } = await mintNft(idToMint, () => { setError("Transaction in progress") });
        console.log(mintedArray)
        if (hasError) {
            setError("Impossible de créer une transaction");
        } else if (isNetworkChangeRejected) {
            setError("Veuillez changer de réseau afin de pouvoir mint votre NFT.")
        } else if (!canPay) {
            setError("Vous n'avez pas assez de fonds.");
        } else if (!isSuccess) {
            setError("Un problème est survenu lors de la validation de la transaction.");
        } else {
            setError("Succès ! Votre transaction a été validée avec succès.");
        }
        setTxSuccess((isSuccess as boolean).toString());
        setHasFunds((canPay as boolean).toString());
    }

    const renderNoAddr = () => {
        if (!connectedAddr && wallet === 'null' ) {
            return (
                <>
                    <div className='test'>
                        <div >
                            
                        </div>
                        <svg  className='cross' viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7072 1.70706L1.70718 15.7071L0.292969 14.2928L14.293 0.292847L15.7072 1.70706Z" fill="black"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.70718 0.292847L15.7072 14.2928L14.293 15.7071L0.292969 1.70706L1.70718 0.292847Z" fill="black"/>
                        </svg>
                    </div>
                    <div className="actions connect_container">
                        <div className="title-description">
                            <div className='title'>Possédez-vous déjà un portefeuille ?</div>
                            <div className="description">Il s’agit d’un compte cryptowallet pour détenir l’oeuvre digitale au format NFT.</div>
                        </div>
                    </div>
                    <div className="wallet-container">
                        <div className="actions__item">
                            <div className='wallet' onClick={async () => {haveAccount(true) }}>
                                <span className='link'>oui </span>
                            </div>
                        </div>
                        <div className="actions__item">
                            <div className='wallet' onClick={async () => {haveAccount(false)}}>
                                <span className='link'>non</span>
                            </div>
                        </div>
                    </div>
                    
                </>
            )
        }
        
        if (!connectedAddr && wallet) {
            return (
                <>
                    <div className='test'>
                        <div  onClick={async () => {returnLoad('null' , false) }}>
                            <svg className='arrow' viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 16L-5.96007e-07 16M12 1L27 16L12 31" stroke="black" stroke-width="2"/>
                            </svg>
                        </div>
                        <svg  className='cross' viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7072 1.70706L1.70718 15.7071L0.292969 14.2928L14.293 0.292847L15.7072 1.70706Z" fill="black"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.70718 0.292847L15.7072 14.2928L14.293 15.7071L0.292969 1.70706L1.70718 0.292847Z" fill="black"/>
                        </svg>
                    </div>
                    <div className="actions connect_container">
                        <div className="title-description">
                            <div className='title'>Choisissez votre portefeuille</div>
                            <div className="description">'Sélectionnez un portefeuille parmi les 3 ci-dessous. </div>
                        </div>
                    </div>
                    <div className='connect-button'>
                        <div className="actions__item">
                            <div className='button _metamask' onClick={async () => {connect("Metamask") }}>
                                <img src={metamask} alt='metamask' />
                                Metamask
                            </div>
                        </div>
                        <div className="actions__item">
                            <div className='button _walletconnect' onClick={async () => {connect("WalletConnect")}}>
                                <img src={walletConnect} alt='walletConnect' />
                                WalletConnect
                            </div>
                        </div>
                        <div className="actions__item">
                            <div className='button _coinbase' onClick={async () => {connect("Coinbase")}}>
                                <img src={coinbase}  alt='coinbase' />
                                Coinbase
                            </div>
                        </div>
                    </div>
                </>
               
            )
        }

        if (!connectedAddr && !wallet) {
            return (
                <>
                    <div className='test'>
                        <div  onClick={async () => {returnLoad('null', false) }}>
                            <svg className='arrow' viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 16L-5.96007e-07 16M12 1L27 16L12 31" stroke="black" stroke-width="2"/>
                            </svg>
                        </div>
                        <svg  className='cross' viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7072 1.70706L1.70718 15.7071L0.292969 14.2928L14.293 0.292847L15.7072 1.70706Z" fill="black"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.70718 0.292847L15.7072 14.2928L14.293 15.7071L0.292969 1.70706L1.70718 0.292847Z" fill="black"/>
                        </svg>
                    </div>
                    <div className='download-container'>
                        Tutoriel pour se connecter à un wallet
                        <a href={pdf} download className='download-link'> <span className='link'>Télécharger le PDF</span></a>
                    </div>
                </>
            )
        }
         if (connectedAddr) {
             return (
                <>
                    <div className='test'>
                        <div  onClick={async () => {returnLoad(true, false) }}>
                            <svg className='arrow' viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 16L-5.96007e-07 16M12 1L27 16L12 31" stroke="black" stroke-width="2"/>
                            </svg>
                        </div>
                        <svg  className='cross' viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7072 1.70706L1.70718 15.7071L0.292969 14.2928L14.293 0.292847L15.7072 1.70706Z" fill="black"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.70718 0.292847L15.7072 14.2928L14.293 15.7071L0.292969 1.70706L1.70718 0.292847Z" fill="black"/>
                        </svg>
                    </div>
                    <div className="details">
                    
                        <p className='account'>Compte : <b>{connectedAddr}</b></p>
                    </div>
                     <div className='wallet' onClick={async () => { mint() }}><span className='link'>acheter le NFT</span></div>
                     <div className='error-message'>
                            {txSuccess && hasFunds &&
                                <span className={txSuccess === 'true' ? 'success' : 'error'}>
                                 Transaction {txSuccess === 'true' ? 'réussi' : 'écouché'}
                                </span>
                            }
                            {
                                hasFunds && 
                                <span>
                                    {`Vous avez des fonds pour mint votre NFT: ${hasFunds}`}
                                </span>
                                }
                         <span className='error'>{error}</span>
                     </div>

                    

                    <div className="">
                        <a  href='/'  onClick={async () => {
                            await disconnectWallet(() => {
                                setConnectedAddr("");
                                localStorage.removeItem('idNft')
                            });
                        }}>
                            <span >Se déconnecter</span>
                        </a>
    
                    </div>
                    
                </>
        
        )
         }
        
    }

        return (
            <>
                <div className="App">
                    
                    

                    {renderNoAddr()}
                    
                </div>
            </>
        );
}

export default App;
