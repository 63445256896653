import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";

export async function disconnectWallet(onDisconnect: () => void = () => {}) {
    const supportedChains = [process.env.REACT_APP_CHAIN_ID as unknown as number];
    const walletInfo = localStorage.getItem("walletInfo")
      ? JSON.parse(localStorage.getItem("walletInfo") as string)
      : undefined;
    
    try {
      if (walletInfo && walletInfo.type === "CoinbaseWallet") {
        // Disconnect Coinbase Wallet
        const walletLink = new WalletLink({
          appName: "Jesus NFT",
          darkMode: true,
        });
        const coinbaseWalletProvider = walletLink.makeWeb3Provider(
          process.env.PROVIDER_URL as string,
          supportedChains[0]
        );
        if (coinbaseWalletProvider && coinbaseWalletProvider.isConnected()) {
          coinbaseWalletProvider.disconnect();
        }
      }
      if (walletInfo && walletInfo.type === "WalletConnect") {
        // Disconnect Wallet Connect
        const walletConnectProvider = new WalletConnectProvider({
          rpc: {
            [supportedChains[0]]: process.env.PROVIDER_URL as string
          },
        });
        if (walletConnectProvider && walletConnectProvider.connected) {
          await walletConnectProvider.disconnect();
        }
      }
      localStorage.removeItem("walletInfo");
      onDisconnect();
    } catch (error) {
      console.log(error);
    }
  }