import { BigNumber, ethers } from "ethers";
import { disconnectWallet } from "../wallet-disconnect";
import jesusAbi from "../../abi/jesus.json";
import { connectWallet } from "../wallet-connection";

export async function watchWalletActivity(
  provider: any,
  onAddressChange: (address: string) => void,
  onDisconnect: () => void = () => { }
) {

  // Subscribe to accounts change
  provider.on("accountsChanged", ([acc]: string[]) => {
    onAddressChange(acc);
  });

  // Subscribe to session disconnection
  provider.on("disconnect", (code: number, reason: string) => {
    onDisconnect();
    disconnectWallet();
    console.log("disconnected");
  });
}

export async function detectConnectedWallet(
  isToSwitchNetwork: boolean,
  onAddressChange: (address: string) => void,
  onDisconnect: () => void = () => { }
) {
  const walletInfo = localStorage.getItem("walletInfo")
    ? JSON.parse(localStorage.getItem("walletInfo") as string)
    : undefined;
  if (walletInfo) {
    let response;
    switch (walletInfo.type) {
      case "CoinbaseWallet":
        response = await connectWallet(
          "Coinbase",
          onAddressChange,
          onDisconnect,
          isToSwitchNetwork
        );
        break;
      case "Metamask":
        response = await connectWallet(
          "Metamask",
          onAddressChange,
          onDisconnect,
          isToSwitchNetwork
        );
        break;
      case "WalletConnect":
        response = await connectWallet(
          "WalletConnect",
          onAddressChange,
          onDisconnect,
          isToSwitchNetwork
        );
        break;
    }

    if (response) {
      return {
        isConnected: response.isConnected,
        provider: response.provider,
        address: response.address,
        isNetworkChangeRejected: response.isNetworkChangeRejected,
        walletType: walletInfo.type
      };
    }
  }
  return {
    isConnected: false,
  };
}

export const getConnectionInfo = async () => {
  const walletInfo = localStorage.getItem("walletInfo");

  if (walletInfo) {
    const { isConnected, address } = await detectConnectedWallet(false, () => { });

    return {
      isConnected,
      address
    }
  }

  return {
    isConnected: false,
    address: undefined
  }
}

export const hasFundsForMint = async (provider: ethers.providers.Web3Provider) => {
  let hasFunds = false;
  try {
    const { address } = await getConnectionInfo();
    const userBalance: BigNumber = await provider?.getBalance(address as string) as BigNumber;
    const jesus = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS as string, jesusAbi.abi, provider);
    const priceInWei: BigNumber = await jesus.getWeiPrice();
    if (userBalance.gt(priceInWei)) {
      hasFunds = true;
    }
  } catch (error) {
    return
  }
  return hasFunds
}
