import { BigNumber, ethers } from "ethers";
import jesusAbi from "../../abi/jesus.json";
import { detectConnectedWallet, hasFundsForMint } from "../utils";

export const mintNft = async (tokenId: number, onTxSent: () => void) => {
    let {isNetworkChangeRejected, provider} = await detectConnectedWallet(true, () => { });
    let hasFunds = await hasFundsForMint(provider as ethers.providers.Web3Provider);
    const signer = provider ? provider.getSigner() : undefined;
    const signerAddress = signer ? await signer.getAddress() : undefined;
    let isSuccess = false;

    if (provider && signer && hasFunds && !isNetworkChangeRejected) {
        try {
            const jesus = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS as string, jesusAbi.abi, provider);
            const priceInWei: BigNumber = await jesus.getWeiPrice();
            const transaction = await jesus.connect(signer).safeMint(tokenId, signerAddress, { value: priceInWei.toString()});
            onTxSent();
            const receipt = await transaction.wait();
            if (receipt.status === 1) {
                isSuccess = true;
            }
        } catch (error: any) {
            return {
                isSuccess,
                hasFunds,
                hasError: true
            }
        }
    }

    return {
        isSuccess,
        hasFunds,
        hasError: false,
        isNetworkChangeRejected
    }
}

export const getBlockedNfts = async (): Promise<number[]> => {
    const provider = new ethers.providers.AlchemyProvider('maticmum', process.env.REACT_APP_PROVIDER_KEY);
    let blockedNftsBn: BigNumber[] = [];
    let blockedNfts: number[] = [];
    if (provider) {
        try {
            const jesus = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS as string, jesusAbi.abi, provider);
            blockedNftsBn = await jesus.preMintedTokens();
            blockedNfts = blockedNftsBn.map((elem: BigNumber) => {
                return elem.toNumber();
            })
        } catch (error) {
            throw error;
        }
    }

    return blockedNfts;
}

export const getMintedNfts = async (): Promise<number[]> => {
    const provider = new ethers.providers.AlchemyProvider('maticmum', process.env.REACT_APP_PROVIDER_KEY);
    let mintedNfts: number[] = [];
    let filter: any;
    if (provider) {
        try {
            const jesus = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS as string, jesusAbi.abi, provider);
            filter = jesus.filters.Transfer(null , null, null);
            const result = await jesus.queryFilter(filter);
            for (let index = 0; index < result.length; index++) {
                const args = result[index].args as ethers.utils.Result;
                if(args[0] === "0x0000000000000000000000000000000000000000"){
                    mintedNfts.push((args[2] as BigNumber).toNumber());  
                }
            }
        } catch (error) {
            throw error;
        }
    }

    return mintedNfts;
}
