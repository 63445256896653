import { ethers } from "ethers";
import { detectConnectedWallet } from "../utils";

export const requestNetworkChange = async (chainId = parseInt(process.env.REACT_APP_CHAIN_ID as string)) => {
  let isRejected = false;
  try {
    let provider = (window as any).ethereum;
    if (provider && provider.providers && provider.providers.length) {
      provider.providers.forEach(async (p: any) => {
        if (p.isMetaMask) provider = p;
      });
    }
    if (provider) {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [
          { 
            chainId: `0x${chainId.toString(16)}`
          }
        ]
      })
  
    }

  } catch (switchError: any) {
    if (switchError.code === 4902) {
      let provider = (window as any).ethereum;
      if (provider && provider.providers && provider.providers.length) {
        provider.providers.forEach(async (p: any) => {
          if (p.isMetaMask) provider = p;
        });
      }
      try {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            { 
              chainName: "Mumbai",
              chainId: `0x${chainId.toString(16)}`,
              rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
              nativeCurrency:{
                name:"Matic",
                symbol:"MATIC",
                decimals: 18
                }
            }
          ]
        });

      } catch (addError: any) {
        if(addError.code === 4001){
          return{
            isRejected: true
          }
        }
      }
    } else if(switchError.code === 4001){
      return{
        isRejected: true
      }
    }
  }
  return {
    isRejected
  }
}

export const getCurrentChainId = async (provider?: ethers.providers.Web3Provider): Promise<number> => {
  if(!provider){
    const { provider: localProvider } = await detectConnectedWallet(false, () => {});
    provider = localProvider;
  }
  if (provider) {
    let chainId = await (provider as ethers.providers.Web3Provider).send('eth_chainId', []);
    chainId = parseInt(chainId, 16);

    return chainId as number;
  }
  return 0
}