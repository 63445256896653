import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import WalletLink from "walletlink";
import { getCurrentChainId, requestNetworkChange } from "../network-interaction";
import { watchWalletActivity } from "../utils";
import { signMessage } from "../wallet-sign";

export const connectWallet = async (
  type: "Metamask" | "Coinbase" | "WalletConnect",
  onAddressChange: (address: string) => void,
  onDisconnect: () => void,
  isToSwitchNetwork: boolean,
  messageToSign?: string,
) => {
  let response:{
    provider: ethers.providers.Web3Provider | undefined, 
    isConnected: boolean | undefined, 
    sigHash?: string ,
    isNetworkChangeRejected?: boolean,
    isSigRejected?: boolean,
    address?: string
  } = {
    provider: undefined,
    isConnected: false,
    sigHash: undefined,
    isNetworkChangeRejected: false,
    isSigRejected: false,
    address: undefined
  };
  try {
    switch (type) {
      case "Metamask":
        response = await requestMetamaskConnection(onAddressChange, onDisconnect, isToSwitchNetwork, messageToSign);
        break;

      case "Coinbase":
        response = await requestCoinbaseConnection(onAddressChange, onDisconnect, messageToSign);
        break;

      case "WalletConnect":
       response = await requestWalletConnect(onAddressChange, onDisconnect, messageToSign);
        break;
    }

    return response;
  } catch (error) {
    throw error;
  }

}

const requestMetamaskConnection = async (
  onAddressChange: (address: string) => void,
  onDisconnect: () => void,
  isToSwitchNetwork: boolean,
  messageToSign?: string,
) => {
  const supportedChains = [process.env.REACT_APP_CHAIN_ID as unknown as number];
  try {
    let provider = (window as any).ethereum;
    if (provider.providers && provider.providers.length) {
      provider.providers.forEach((p: any) => {
        if (p.isMetaMask) provider = p;
      });
    }
  
    const web3Provider = new ethers.providers.Web3Provider(
      provider
    );

    const walletInfo = localStorage.getItem("walletInfo") ? JSON.parse(localStorage.getItem("walletInfo") as string) : undefined;
    let sigHash;
    let isSigRejected;
    let isNetworkChangeRejected;

    if((await getCurrentChainId(web3Provider) !== (process.env.REACT_APP_CHAIN_ID as unknown as number)) && isToSwitchNetwork ){
      const {isRejected: networkChangeRejected} = await requestNetworkChange(parseInt(process.env.REACT_APP_CHAIN_ID as unknown as string));
      isNetworkChangeRejected = networkChangeRejected;
    }

    if(isNetworkChangeRejected){
     return { provider: undefined, isConnected: false, sigHash, isSigRejected, isNetworkChangeRejected };
    }

    if ((walletInfo ? walletInfo.type !== "Metamask" : true)) {
      await provider.request({ method: "eth_requestAccounts" });
      const { signatureHash, wasRejected } = await signMessage(messageToSign as string, web3Provider);
      sigHash = signatureHash;
      isSigRejected = wasRejected;
      if (wasRejected) {
        return { provider: undefined, isConnected: false, sigHash, isSigRejected };
      }
    }

    const signer = web3Provider.getSigner();
    const address = await signer.getAddress();

    watchWalletActivity(
      provider,
      onAddressChange,
      onDisconnect,
    );

    localStorage.setItem(
      "walletInfo",
      JSON.stringify({
        address,
        type: "Metamask",
      })
    );
    return { provider: web3Provider, address, isConnected: true, sigHash, isSigRejected };
  } catch (error: any) {
    console.log(error.code);
    return { provider: undefined, address: undefined, isConnected: false };
  }

}

const requestCoinbaseConnection = async (
  onAddressChange: (address: string) => void,
  onDisconnect: () => void,
  messageToSign?: string
) => {
  const supportedChains = [process.env.REACT_APP_CHAIN_ID as unknown as number];
  try {
    // Initialize WalletLink
    const walletLink = new WalletLink({
      appName: "Jesus NFT",
      darkMode: true,
    });

    // Initialize a Web3 Provider object
    const provider = walletLink.makeWeb3Provider(
      process.env.REACT_APP_PROVIDER_URL as string,
      process.env.REACT_APP_CHAIN_ID as unknown as number
    );

    const web3Provider = new ethers.providers.Web3Provider(provider as any);

    const walletInfo = localStorage.getItem("walletInfo") ? JSON.parse(localStorage.getItem("walletInfo") as string) : undefined;

    let sigHash;
    let isSigRejected;

    if ((walletInfo ? walletInfo.type !== "CoinbaseWallet" : true) && messageToSign) {
      await provider.request({ method: "eth_requestAccounts" });
      const { signatureHash, wasRejected } = await signMessage(messageToSign as string, web3Provider);
      sigHash = signatureHash;
      isSigRejected = wasRejected;
      if (wasRejected) {
        return { provider: undefined, isConnected: false, sigHash, isSigRejected };
      }
    }
    const signer = await web3Provider.getSigner();
    const address = await signer.getAddress();

    watchWalletActivity(
      provider,
      onAddressChange,
      onDisconnect
    );

    localStorage.setItem(
      "walletInfo",
      JSON.stringify({
        address,
        type: "CoinbaseWallet",
      })
    );
    return { provider: web3Provider, address, isConnected: true, sigHash, isSigRejected };
  } catch (error) {
    console.log(error);
    return { provider: undefined, address: undefined, isConnected: false };
  }
}


const requestWalletConnect = async (
  onAddressChange: (address: string) => void,
  onDisconnect: () => void,
  messageToSign?: string
) => {
  const supportedChains = [process.env.REACT_APP_CHAIN_ID as unknown as number];
  try {
    //  Create WalletConnect Provider
    const provider = new WalletConnectProvider({
      rpc: {
        [supportedChains[0]]: process.env.REACT_APP_PROVIDER_URL as string
      },
    });
    const web3Provider = new ethers.providers.Web3Provider(provider);

    //  Enable session (triggers QR Code modal)
    const [address] = await provider.enable();
    const walletInfo = localStorage.getItem("walletInfo") ? JSON.parse(localStorage.getItem("walletInfo") as string) : undefined;

    let sigHash;
    let isSigRejected;
    if (walletInfo ? walletInfo.type !== "WalletConnect" : true) {
      const { signatureHash, wasRejected } = await signMessage(messageToSign as string, web3Provider);
      sigHash = signatureHash;
      isSigRejected = wasRejected;
      if (isSigRejected) {
        return { provider: undefined, isConnected: false, sigHash, isSigRejected };
      }

    }

    watchWalletActivity(
      provider,
      onAddressChange,
      onDisconnect
    );

    localStorage.setItem(
      "walletInfo",
      JSON.stringify({
        address,
        type: "WalletConnect",
      })
    );
    return { provider: web3Provider, address, isConnected: true, sigHash, isSigRejected };
  } catch (error) {
    console.log(error);
    return { provider: undefined, address: undefined, isConnected: false };
  }
}