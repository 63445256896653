import { ethers } from "ethers";

export const signMessage = async (message: string, provider: ethers.providers.Web3Provider) => {

    let signatureHash = "";
    let wasRejected = false;
    try {
        if(provider){
            const signer = provider.getSigner();
            signatureHash = await signer.signMessage(message);
        }
    } catch (error:any) {
        return {
            signatureHash,
            wasRejected: true
        }
    }

    return {
        signatureHash,
        wasRejected
    }
}
